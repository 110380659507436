import _objectSpread from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _typeof from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
export default {
  name: "KTBrand",
  mounted: function mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]); // Init Aside Menu Toggle

    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
  },
  methods: {
    siteLogo: function siteLogo() {
      var menuAsideLeftSkin = this.layoutConfig("brand.self.theme"); // set brand logo

      var logoObject = this.layoutConfig("self.logo");
      var logo;

      if (typeof logoObject === "string") {
        logo = logoObject;
      }

      if (_typeof(logoObject) === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }

      if (typeof logo === "undefined") {
        var logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }

      return process.env.BASE_URL + logo;
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig"])), {}, {
    allowMinimize: function allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    }
  })
};