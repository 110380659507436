import _objectSpread from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import DatePicker from "@/view/components/DatePicker";
import Moment from "moment-timezone";
import ObjectPath from "object-path";
import { toSafeInteger } from "lodash";
import { EventBus } from "@/core/event-bus/event.bus";
import { QUERY } from "@/core/services/store/request.module";
Moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "KTHeader",
  data: function data() {
    return {
      is_activity_log: false,
      show_setting: false,
      open_detail: false,
      is_searching: false,
      is_focused: false,
      search_type: "any",
      search_tag: "any",
      search_owner: "any",
      search_date_modified: "any-time",
      search_owned_by: null,
      search_shared_to: null,
      search_item_name: null,
      search_include_the_word: null,
      search_start_date: null,
      search_end_date: null,
      search_location_bin: false,
      search_location_starred: false,
      search_location_locked: false,
      type_list: [{
        text: "Any",
        value: "any",
        icon: null,
        color: null
      }, {
        text: "Photos & Images",
        value: "photos-images",
        icon: "mdi-image",
        color: "red darken-2"
      }, {
        text: "PDFs",
        value: "pdfs",
        icon: "mdi-file-pdf-box",
        color: "red darken-1"
      }, {
        text: "Documents",
        value: "documents",
        icon: "mdi-file-document",
        color: "blue darken-1"
      }, {
        text: "Spreadsheets",
        value: "spreadsheets",
        icon: "mdi-google-spreadsheet",
        color: "green darken-2"
      }, {
        text: "Presentations",
        value: "presentations",
        icon: "mdi-file-presentation-box",
        color: "yellow darken-3"
      }, {
        text: "Audio",
        value: "audio",
        icon: "mdi-headphones",
        color: "red darken-2"
      }, {
        text: "Video",
        value: "video",
        icon: "mdi-movie",
        color: "red darken-2"
      }, {
        text: "Archives (zip)",
        value: "archives-zip",
        icon: "mdi-folder-zip",
        color: "grey darken-3"
      }, {
        text: "Folders",
        value: "folders",
        icon: "mdi-folder",
        color: "grey darken-3"
      }],
      owner_list: [{
        text: "Any",
        value: "any",
        icon: null,
        color: null
      }, {
        text: "Owned by me",
        value: "owned-by-me",
        icon: null,
        color: null
      }, {
        text: "Not owned by me",
        value: "not-owned-by-me",
        icon: null,
        color: null
      }, {
        text: "Specific person...",
        value: "specific-person",
        icon: null,
        color: null
      }],
      date_list: [{
        text: "Any time",
        value: "any-time",
        icon: null,
        color: null
      }, {
        text: "Today",
        value: "today",
        icon: null,
        color: null
      }, {
        text: "Yesterday",
        value: "yesterday",
        icon: null,
        color: null
      }, {
        text: "Last 7 days",
        value: "last-seven-days",
        icon: null,
        color: null
      }, {
        text: "Last 30 days",
        value: "last-thirty-days",
        icon: null,
        color: null
      }, {
        text: "Last 90 days",
        value: "last-ninty-days",
        icon: null,
        color: null
      }, {
        text: "Custom...",
        value: "custom",
        icon: null,
        color: null
      }],
      tag_list: [{
        text: "Any",
        value: "any",
        icon: null,
        color: null
      }],
      timeout: null,
      timeout_limit: 500
    };
  },
  watch: {
    $route: function $route() {
      this.init_filters();
    },
    search_item_name: function search_item_name(param) {
      if (!param) {
        this.keypress_search();
      }
    }
  },
  components: {
    DatePicker: DatePicker,
    KTTopbar: KTTopbar // KTMenu,

  },
  methods: {
    is_dashboard: function is_dashboard() {
      var route_name = ObjectPath.get(this.$route, "name", null);

      if (route_name == "dashboard") {
        return true;
      }

      return false;
    },
    site_logo: function site_logo() {
      return "".concat(process.env.BASE_URL, "media/logos/logo-genic-text.png");
    },
    init_filters: function init_filters() {
      this.search_type = ObjectPath.get(this.$route, "query.search-type", "any");
      this.search_owner = ObjectPath.get(this.$route, "query.search-owner", "any");
      this.search_tag = ObjectPath.get(this.$route, "query.search-tag", "any");
      this.search_date_modified = ObjectPath.get(this.$route, "query.search-date-modified", "any-time");
      this.search_owned_by = ObjectPath.get(this.$route, "query.search-owned-by", null);
      this.search_shared_to = ObjectPath.get(this.$route, "query.search-shared-to", null);
      this.search_item_name = ObjectPath.get(this.$route, "query.search-item-name", null);
      this.search_include_the_word = ObjectPath.get(this.$route, "query.search-include-the-word", null);
      this.search_start_date = ObjectPath.get(this.$route, "query.search-start-date", null);
      this.search_end_date = ObjectPath.get(this.$route, "query.search-end-date", null);
      var search_location_bin = ObjectPath.get(this.$route, "query.search-location-bin", 0);
      this.search_location_bin = toSafeInteger(search_location_bin);
      var search_location_starred = ObjectPath.get(this.$route, "query.search-location-starred", 0);
      this.search_location_starred = toSafeInteger(search_location_starred);
      var search_location_locked = ObjectPath.get(this.$route, "query.search-location-locked", 0);
      this.search_location_locked = toSafeInteger(search_location_locked);
    },
    reset_search: function reset_search() {
      var _this2 = this;

      this.search_type = "any";
      this.search_owner = "any";
      this.search_tag = "any";
      this.search_date_modified = "any-time";
      this.search_owned_by = null;
      this.search_shared_to = null;
      this.search_item_name = null;
      this.search_include_the_word = null;
      this.search_start_date = null;
      this.search_end_date = null;
      this.search_location_bin = false;
      this.search_location_starred = false;
      this.search_location_locked = false;
      this.$nextTick(function () {
        _this2.do_search();
      });
    },
    keypress_search: function keypress_search() {
      var _this = this;

      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        _this.do_search();
      }, _this.timeout_limit);
    },
    do_search: function do_search() {
      var _this3 = this;

      var query = {
        "search-type": this.search_type || undefined,
        "search-owner": this.search_owner || undefined,
        "search-tag": this.search_tag || undefined,
        "search-date-modified": this.search_date_modified || undefined,
        "search-owned-by": this.search_owned_by || undefined,
        "search-shared-to": this.search_shared_to || undefined,
        "search-item-name": this.search_item_name || undefined,
        "search-include-the-word": this.search_include_the_word || undefined,
        "search-start-date": this.search_start_date || undefined,
        "search-end-date": this.search_end_date || undefined,
        "search-location-bin": this.search_location_bin || undefined,
        "search-location-starred": this.search_location_starred || undefined,
        "search-location-locked": this.search_location_locked || undefined,
        t: Moment().unix()
      };
      this.is_searching = false;
      this.is_focused = false;
      this.$router.push({
        name: this.$route.name,
        query: _objectSpread(_objectSpread({}, this.$route.query), query)
      }).then(function () {
        _this3.open_detail = false;
      }).catch(function () {//
      });
    },
    on_blur: function on_blur() {
      this.is_searching = false;
      this.is_focused = false;
    },
    on_esc: function on_esc() {
      var _this4 = this;

      this.is_searching = false;
      this.is_focused = false;
      this.search_item_name = null;
      this.$nextTick(function () {
        _this4.do_search();
      });
    },
    get_tags: function get_tags() {
      var _this5 = this;

      this.$store.dispatch(QUERY, {
        url: "account/tag?t=".concat(new Date().getTime())
      }).then(function (response) {
        _this5.tag_list = response;
      }).catch(function (error) {
        console.log({
          error: error
        });
      });
    }
  },
  mounted: function mounted() {
    var _this6 = this;

    this.get_tags(); // Init Desktop & Mobile Headers

    KTLayoutHeader.init("kt_header", "kt_header_mobile"); // Init Header Menu

    KTLayoutHeaderMenu.init(this.$refs["kt_header_menu"], this.$refs["kt_header_menu_wrapper"]);
    var headerRef = this.$refs["kt_header"];
    headerRef.querySelectorAll("a[class='menu-link']").forEach(function (item) {
      item.addEventListener("click", function () {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
    EventBus.$on("show-search-setting", function (param) {
      _this6.show_setting = param;
      _this6.open_detail = false;
    });
    EventBus.$on("is-activity-log", function (param) {
      _this6.is_activity_log = param;
    });
  },
  beforeDestroy: function beforeDestroy() {
    EventBus.$off("show-search-setting");
    EventBus.$off("is-activity-log");
  },
  beforeMount: function beforeMount() {
    this.init_filters();
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig", "getClasses"])), {}, {
    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled: function headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses: function headerClasses() {
      var classes = this.getClasses("header");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses: function headerMenuClasses() {
      var classes = this.getClasses("header_menu");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    },
    placeholder: function placeholder() {
      return this.is_focused ? "Start Typing then press enter" : "Search ...";
    }
  })
};