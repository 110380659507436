import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import objectPath from "object-path";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module";
import store from "@/core/services/store";
import { ADD_CLASSNAME } from "@/core/services/store/htmlclass.module";
var HtmlClass = {
  config: null,
  init: function init(config) {
    if (typeof config !== "undefined") {
      this.config = config;
    } // init base layout


    this.initLayout(); // init header and subheader menu

    this.initHeader();
    this.initSubheader(); // init aside and aside menu

    this.initAside(); // init footer

    this.initFooter(); // init themes

    this.initThemes();
  },

  /**
   * Init Layout
   */
  initLayout: function initLayout() {
    if (objectPath.has(this.config, "self.body.class")) {
      var _selfBodyClass = objectPath.get(this.config, "self.body.class").toString();

      if (_selfBodyClass) {
        var bodyClasses = _selfBodyClass.split(" ");

        bodyClasses.forEach(function (cssClass) {
          store.dispatch(ADD_BODY_CLASSNAME, cssClass);
        });
      }
    }

    var bgImage = objectPath.get(this.config, "self.body.background-image");

    if (typeof bgImage !== "undefined") {
      document.body.style.backgroundImage = "url(".concat(bgImage, ")");
    } // Offcanvas directions


    store.dispatch(ADD_BODY_CLASSNAME, "quick-panel-right");
    store.dispatch(ADD_BODY_CLASSNAME, "demo-panel-right");
    store.dispatch(ADD_BODY_CLASSNAME, "offcanvas-right"); // Properly close mobile header menu

    store.dispatch(REMOVE_BODY_CLASSNAME, "header-menu-wrapper-on");
  },

  /**
   * Init Header
   */
  initHeader: function initHeader() {
    // Fixed header
    if (objectPath.get(this.config, "header.self.fixed.desktop")) {
      store.dispatch(ADD_BODY_CLASSNAME, "header-fixed");
      store.dispatch(ADD_CLASSNAME, {
        position: "header",
        className: "header-fixed"
      });
    } else {
      store.dispatch(ADD_BODY_CLASSNAME, "header-static");
    }

    if (objectPath.get(this.config, "header.self.fixed.mobile")) {
      store.dispatch(ADD_BODY_CLASSNAME, "header-mobile-fixed");
      store.dispatch(ADD_CLASSNAME, {
        position: "header_mobile",
        className: "header-mobile-fixed"
      });
    }

    if (objectPath.get(this.config, "header.menu.self.display")) {
      store.dispatch(ADD_CLASSNAME, {
        position: "header_menu",
        className: "header-menu-layout-".concat(objectPath.get(this.config, "header.menu.self.layout"))
      }); // Menu

      if (objectPath.get(this.config, "header.menu.self.root-arrow")) {
        store.dispatch(ADD_CLASSNAME, {
          position: "header_menu",
          className: "header-menu-root-arrow"
        });
      }
    }
  },

  /**
   * Init Subheader
   */
  initSubheader: function initSubheader() {
    // Fixed content head
    if (objectPath.get(this.config, "subheader.fixed") && objectPath.get(this.config, "header.self.fixed.desktop")) {
      store.dispatch(ADD_BODY_CLASSNAME, "subheader-fixed");
    }

    if (objectPath.get(this.config, "subheader.display")) {
      store.dispatch(ADD_BODY_CLASSNAME, "subheader-enabled");
    }

    if (objectPath.has(this.config, "subheader.style")) {
      store.dispatch(ADD_BODY_CLASSNAME, "subheader-".concat(objectPath.get(this.config, "subheader.style")));
    }
  },

  /**
   * Init Aside
   */
  initAside: function initAside() {
    // Reset aside class in body
    store.dispatch(REMOVE_BODY_CLASSNAME, "aside-enabled");
    store.dispatch(REMOVE_BODY_CLASSNAME, "aside-fixed");
    store.dispatch(REMOVE_BODY_CLASSNAME, "aside-static");
    store.dispatch(REMOVE_BODY_CLASSNAME, "aside-minimize");

    if (objectPath.get(this.config, "aside.self.display") !== true) {
      return;
    } // Add aside class enabled in body


    store.dispatch(ADD_BODY_CLASSNAME, "aside-enabled"); // Fixed Aside

    if (objectPath.get(this.config, "aside.self.fixed")) {
      store.dispatch(ADD_BODY_CLASSNAME, "aside-fixed");
      store.dispatch(ADD_CLASSNAME, {
        position: "aside",
        className: "aside-fixed"
      });
    } else {
      store.dispatch(ADD_BODY_CLASSNAME, "aside-static");
    } // Default fixed


    if (objectPath.get(this.config, "aside.self.minimize.default")) {
      store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");
    } // Dropdown Submenu


    if (objectPath.get(this.config, "aside.menu.dropdown")) {
      store.dispatch(ADD_CLASSNAME, {
        position: "aside_menu",
        className: "aside-menu-dropdown"
      });
    }
  },

  /**
   * Init Footer
   */
  initFooter: function initFooter() {
    // Fixed header
    if (objectPath.get(this.config, "footer.fixed")) {
      store.dispatch(ADD_BODY_CLASSNAME, "footer-fixed");
    }
  },

  /**
   * Import theme SCSS file based on the selected theme
   */
  initThemes: function initThemes() {
    if (objectPath.get(this.config, "header.self.theme")) {
      var theme = objectPath.get(this.config, "header.self.theme");
      import("@/assets/sass/themes/layout/header/base/".concat(theme, ".scss"));
    }

    if (objectPath.get(this.config, "header.menu.desktop.submenu.theme")) {
      var _theme = objectPath.get(this.config, "header.menu.desktop.submenu.theme");

      import("@/assets/sass/themes/layout/header/menu/".concat(_theme, ".scss"));
    }

    if (objectPath.get(this.config, "brand.self.theme")) {
      var _theme2 = objectPath.get(this.config, "brand.self.theme");

      import("@/assets/sass/themes/layout/brand/".concat(_theme2, ".scss"));
    }

    if (objectPath.get(this.config, "aside.self.theme")) {
      var _theme3 = objectPath.get(this.config, "aside.self.theme");

      import("@/assets/sass/themes/layout/aside/".concat(_theme3, ".scss"));
    }
  }
};
export default HtmlClass;