//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
export default {
  name: "KTTopbar",
  components: {
    KTQuickUser: KTQuickUser
  }
};