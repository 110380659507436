import _objectSpread from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
export default {
  name: "KTHeaderMobile",
  components: {},
  mounted: function mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig", "getClasses"])), {}, {
    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo: function headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.dark");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses: function headerClasses() {
      var classes = this.getClasses("header_mobile");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled: function asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  })
};